import { AppResponse } from "./../models/Response";
import axios from "axios";
import { Util } from "./../Util";
import {
  TrainingDocumentFolder,
  TrainingDocumentFoldersFiles,
  TrainingDocumentPresignUrls,
  TrainingDocumentReq,
  CreateTrainingDocumentFile,
  TrainingDocumetPreviewUrl,
} from "./../models/TrainingDocumentFolders";

export class TrainingDocumentFoldersService {
  public static async createTrainingDocumentFolder(data: Partial<TrainingDocumentFolder>): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("createTrainingDocumentFolder");

    return await axios.post<Partial<TrainingDocumentFolder>, AppResponse<any>>(url, data);
  }

  public static async getDocumentFoldersAndFiles(parentFolderId?: string | null, nameOrder?: string): Promise<AppResponse<TrainingDocumentFoldersFiles>> {
    let url =
      parentFolderId != null
        ? Util.apiPublicUrl("getTrainingDocumentFoldersAndFiles" + "/" + parentFolderId)
        : Util.apiPublicUrl("getTrainingDocumentFoldersAndFiles");
    
    if (nameOrder) {
      url += `?name_order=${nameOrder}`;
    }

    return await axios.get<TrainingDocumentFoldersFiles, AppResponse<TrainingDocumentFoldersFiles>>(url);
  }

  public static async getPreSignedUrls(data: {
    parentFolderId: string | null;
    files: { name: string; type: string }[];
  }): Promise<AppResponse<TrainingDocumentPresignUrls[]>> {
    const url = Util.apiPublicUrl("generatePresignedURLForAccessTrainingFiles");

    return await axios.post<TrainingDocumentReq, AppResponse<TrainingDocumentPresignUrls[]>>(url, data);
  }

  public static async createTrainingDocumentFiles(data: CreateTrainingDocumentFile): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("createTrainingDocumentFolderFile");

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getFilePreviewPreSignUrl(data: {
    parentFolderId: string | null;
    originalFileName: string;
    fileNameInAwsBucket: string;
  }): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("generatePresignedURLForGetFiles");

    return axios.post<Partial<any>, AppResponse<TrainingDocumetPreviewUrl>>(url, data);
  }
}
