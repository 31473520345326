import React, { useState, useContext, useEffect, useCallback, ChangeEvent } from "react";
import SideNav from "./SideNav";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ListGroup,
  DropdownItem,
  Dropdown,
  DropdownMenu,
  ListGroupItem,
  Progress,
  Form,
  FormGroup,
  Input,
  Label,
  DropdownToggle,
  Tooltip,
} from "reactstrap";
import DocumentButtonList from "./DocumentButtonList";
import SearchComponent from "./SearchComponent";
import FolderModal from "./FolderModal";
import FileFolderModal from "./FileFolderModal";

import { Folder, UploadedItem } from "./types";
import FolderListComponent from "./FolderListComponent";
import { IoCloudUploadOutline } from "react-icons/io5";
import { CiFileOn } from "react-icons/ci";
import { TrainingDocumentFolder, CreateTrainingDocumentFile, PreSignedUrl, TrainingDocumentFile } from "./../../models/TrainingDocumentFolders";
import { TrainingDocumentFoldersService } from "./../../services/TrainingDocumentFoldersService";
import { toast } from "react-toastify";
import UserContext from "./../../context/UserContext";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import FilePreviewModal from "./FilePreviewModal";
import { AdminService } from "src/services/AdminService";
import { Role } from "src/models/Role";
import RenameFileModal from "./RenameFileModal";
import RenameFolderModal from "./RenameFolderModal";
import Spinner from "./../../common/spinner/spinner";

interface FileToolTipState {
  [key: string]: boolean;
}

const FolderComponent = () => {
  const [isOpenLeftMenu, setIsOpenLeftMenu] = useState(false);
  const [tabNumber, setTabNumber] = useState(0);
  const [items, setItems] = useState<UploadedItem[]>([]);
  const [fileFolderModalOpen, setFileFolderModalOpen] = useState(false);
  const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);
  const [createDropdownOpen, setCreateDropdownOpen] = useState(false);
  const [uploadDropdownOpen, setUploadDropdownOpne] = useState(false);
  const [selectFolder, setSelectFolder] = useState<TrainingDocumentFolder>();
  const [folders, setFolders] = useState<TrainingDocumentFolder[]>([]);

  const [files, setFiles] = useState<TrainingDocumentFile[]>([]);
  const [folderName, setFolderName] = useState("");
  const [dropFiles, setDropFiles] = useState<File[]>([]);

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);
  const [uploadMessage, setUploadMessage] = useState<string[]>([]);
  const [fileTitle, setFileTitle] = useState<string>("");
  const [fileDescription, setFileDescription] = useState<string>("");
  const [fileDetailsError, setFileDetailsError] = useState<string>("");
  const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] = useState<boolean>(false);
  const [selectFile, setSelectFile] = useState<CreateTrainingDocumentFile>();
  const [selectPresignUrl, setSelectPresignUrl] = useState("");

  const toggleLeftMenuOpen = () => setIsOpenLeftMenu(prevState => !prevState);
  const toggleFileFolderModal = () => setFileFolderModalOpen(prevState => !prevState);

  const toggleCreateFolderModal = () => setCreateFolderModalOpen(prevState => !prevState);
  const toggleCreateDropDown = () => setCreateDropdownOpen(prevState => !prevState);
  const toggle = () => setUploadDropdownOpne(prevState => !prevState);

  const toggleFilePreviewModal = () => setIsFilePreviewModalOpen(prevState => !prevState);
  const [user] = useContext(UserContext);
  const { id } = useParams<{ id: string }>();

  const [isDeleteActionDropDownOpen, setIsDeleteActionDropDownOpen] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState<{ [key: string]: boolean }>({});
  const [dropdownFileOpen, setDropdownFileOpen] = useState<{ [key: string]: boolean }>({});
  const [rightClickedFile, setRightClickedFile] = useState<string | null>(null);
  const [isFileDeleting, setIsFileDeleting] = useState(false);
  const [isDeletingFolder, setIsDeletingFolder] = useState(false);
  const [isOpenRenameFileModal, setIsOenRenameFileModal] = useState(false);
  const [reanemFile, setRenameFileName] = useState("");
  const [renameFileId, setRenameFileId] = useState("");
  const [isOpenRenameFolderModal, setIsOpenRenameFolderModal] = useState(false);
  const [renameFolderId, setRenameFolderId] = useState("");
  const [renameFolder, setRenameFolder] = useState("");
  const [searchString, setSearchString] = useState("");
  const [isClickSearchButton, setIsClickSearchButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState<string>("asc");
  const [sortDropdownOpen, setSortDropdownOpen] = useState(false);

  const [filetooltipOpen, setFileTooltipOpen] = useState<FileToolTipState>({});

  const handleMouseEnter = (id: any) => {

    setFileTooltipOpen(prevState => ({
      ...prevState,
      [id]: true,
    }));
  };

  const handleMouseLeave = (id: any) => {

    setFileTooltipOpen(prevState => ({
      ...prevState,
      [id]: false,
    }));
  };

  const toggleTooltip = (id: any) => {

    setFileTooltipOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const searchFilesAndFolder = async () => {
    try {
      setIsClickSearchButton(true);
      console.log("Search API Call with nameOrder=", sortOrder);
      const data = {
        parentFolderId: id,
        searchString: searchString,
        nameOrder: sortOrder,
      };
      setIsLoading(true);
      const result = await AdminService.searchFilesAndFolders(data);

      if (result.success) {
        setFolders([]);
        setFiles([]);
        setIsLoading(false);
        result?.data?.folders.length === 0 ? setFolders([]) : setFolders(result.data.folders);
        result?.data?.files.length === 0 ? setFiles([]) : setFiles(result?.data.files);
      }
      console.log(result);
    } catch (error) {
      setSearchString("");
      console.log(error);
    }
  };

  const handleSearchString = (searchName: string) => {
    setSearchString(searchName);
  };

  const toggleFolderRenameModal = () => {
    setIsOpenRenameFolderModal(prev => !prev);
  };


  const toggleRenameModalOpen = () => {
    setIsOenRenameFileModal(prev => !prev);
  };

  const changeFolderName = (folderName: string) => {
    setRenameFolder(folderName);
  };

  const renameFolderName = async () => {
    try {
      const folderId = renameFolderId;
      const folderName = renameFolder;

      const data = {
        folderId,
        folderName,
      };
      if (
        (user && user?.grantedAccessFileFolderPermission) ||
        (user?.role && user?.role === Role.SUPER_ADMIN) ||
        (user?.role && user?.role === Role.SUB_ADMIN)
      ) {
        setIsLoading(true);
        const result = await AdminService.renameFolder(data);

        if (result.success) {
          toast.success("Folder Name is Successfully Edited.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          toggleFolderRenameModal();
          setIsLoading(false);
        }
      } else {
        toast.error("You have not granted to rename Folder.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
         toggleFolderRenameModal();
      }
    } catch (error) {
      toast.error("Unabel to Edit Folder Name.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      console.log(error);
    }
  };

  const selectedFolder = (folder: TrainingDocumentFolder) => {
    console.log(folder);
    setSelectFolder(folder);
    setRenameFolder(folder.folderName);
    setRenameFolderId(folder._id);
  };

  const renameFileName = async () => {
    try {
      const fileId = renameFileId;
      const originalFileName = reanemFile;

      if (
        (user && user?.grantedAccessFileFolderPermission) ||
        (user?.role && user?.role === Role.SUPER_ADMIN) ||
        (user?.role && user?.role === Role.SUB_ADMIN)
      ) {
        const data = {
          fileId,
          originalFileName,
        };

        setIsLoading(true);

        const result = await AdminService.renameFile(data);
        if (result.success) {
          toast.success("File Name is Successfully Edited.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          toggleRenameModalOpen();
          setIsLoading(false);
        }
        console.log("rename result");
        console.log(result);
      } else {
        toast.error("You have not granted to rename files.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
         toggleRenameModalOpen();
      }
    } catch (error) {
      toast.error("Unabel to Edit File Name.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      console.log(error);
    }
  };

  const renameFileDetails = (file: TrainingDocumentFile) => {
    setRenameFileName(file.originalFileName);
    setRenameFileId(file._id);
  };

  const changeRenameFile = (e: any) => {
    setRenameFileName(e.target.value);
  };


  const toggleDeleteActionDropDown = (elemrnt: TrainingDocumentFolder) => {
    setDropdownOpen({ [elemrnt._id]: !dropdownOpen[elemrnt._id] });
  };

  const handleClickOutsideFile = () => {
    setDropdownFileOpen({});
    setRightClickedFile(null);
  };

  const handleContextFileMenu = (event: React.MouseEvent, cardId: string) => {

    event.preventDefault();
    setDropdownFileOpen({ [cardId]: true });

    event.stopPropagation();

    setRightClickedFile(cardId);
  };

  const handleClickOutside = () => {
    setDropdownOpen({});
  };

  const handleContextMenu = (event: React.MouseEvent, cardId: string) => {
    event.preventDefault();
    setDropdownOpen({ [cardId]: true });
  };

  // const handleClickOutside = () => {
  //   setIsDeleteActionDropDownOpen(false);
  // };

  // const handleContextMenu = (event: React.MouseEvent) => {
  //   event.preventDefault();
  //   setIsDeleteActionDropDownOpen(true);
  // };

  // const toggleDeleteActionDropDown = () => {
  //   setIsDeleteActionDropDownOpen(prevState => !prevState);
  // };

  const sideNavTabNumber = (number: number) => {
    setTabNumber(number);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(items);
    const fileList = event.target.files;

    if (!fileList) return;

    const itemsArray: UploadedItem[] = [];

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      itemsArray.push({
        name: file.name,
        path: (file as any).webkitRelativePath || file.name,
        type: file.type,
        size: file.size,
        isDirectory: !file.type,
      });
    }

    setItems(itemsArray);
  };

  const createFolder = async (name: string) => {
    console.log(name);
    setFolderName(name);

    const data = {
      folderName: name,
      createdBy: user?._id,
      parentFolderId: id,
    };

    try {
      // if (user && !user.grantedAccessFileFolderPermission) {
      //   toast.error("You have not allowed to create folders", {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     className: "foo-bar",
      //   });
      //   return;
      // }
      if (
        (user && user?.grantedAccessFileFolderPermission) ||
        (user?.role && user?.role === Role.SUPER_ADMIN) ||
        (user?.role && user?.role === Role.SUB_ADMIN)
      ) {
        if (name) {
          setIsLoading(true);
          const result = await TrainingDocumentFoldersService.createTrainingDocumentFolder(data);
          setFolderName("");
          console.log(result);

          if (result.success) {
            toast.success("Folder is successfully created.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setFolderName("");
            setIsLoading(false);
          }
        } else {
          toast.error("Folder Name is Required.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        toast.error("You have not allowed to create folders", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      toggleCreateFolderModal();
      setFolderName("");
    } catch (error) {
      console.log(error);
      setFolderName("");
      toast.error("Error occured", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const getAllFilesAndFolders = async (folderId: string, orderValue?: string) => {
    try {
      setIsLoading(true);
      // Sử dụng tham số orderValue nếu được cung cấp, nếu không thì dùng state
      const orderToUse = orderValue || sortOrder;
      console.log("API Call with name_order=", orderToUse);
      
      const result = await TrainingDocumentFoldersService.getDocumentFoldersAndFiles(folderId, orderToUse);
      console.log("folders and files");
      setFolders([]);
      setFiles([]);
      if (result.success) {
        result?.data?.folders.length === 0 ? setFolders([]) : setFolders(result.data.folders);
        result?.data?.files.length === 0 ? setFiles([]) : setFiles(result?.data.files);
        setIsLoading(false);
      }
      console.log(result);
    } catch (error) {
      setIsLoading(true);
      console.log(error);
      toast.error("Failed to load folders and files", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const handleOpenInNewTab = async (file: TrainingDocumentFile) => {

     setFileTooltipOpen((prevState) => ({
      ...prevState,
      [file._id]: false,
     }));

    const parentFolderId = id;
    const originalFileName = file.originalFileName;
    const fileNameInAwsBucket = file.fileNameInAwsBucket;
    console.log(originalFileName);
    setSelectFile(file);

    try {
      setIsLoading(true);
      const result = await TrainingDocumentFoldersService.getFilePreviewPreSignUrl({ parentFolderId, originalFileName, fileNameInAwsBucket });
      console.log("preview presign url");
      console.log(result.data);
      if (result.success) {
        toggleFilePreviewModal();
        setSelectPresignUrl(result?.data);
        setIsLoading(false);
        //  window.open(result?.data as string, "_blank");
      }
    } catch (error) {
      toast.error("Failed to load requested Files", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      console.log(error);
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // setDropFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
    setDropFiles([...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  const handleUpload = async () => {
    console.log("file upload title description");
    console.log(fileTitle);
    console.log(fileDescription);
    if (!fileTitle || !fileDescription) {
      setFileDetailsError("File Title and Description are required");
      return;
    }
    setFileDetailsError("");

    if (dropFiles.length === 0) return;
    setIsFileUploading(true);

    const filesArray = Array.from(dropFiles);

    console.log("folder component array");
    console.log(filesArray);

    const userId = user?._id || "";

    try {
      setIsLoading(true);
      const response = await TrainingDocumentFoldersService.getPreSignedUrls({
        files: filesArray.map(file => ({
          name: file.name,
          type: file.type,
        })),
        parentFolderId: id,
      });

      console.log(response);

      for (const data of response.data) {
        console.log(data);
        if (!data.success) {
          uploadMessage.push(`${data.fileName} : ${data.message}`);
          setUploadMessage([...uploadMessage, `${data.fileName} : ${data.message}`]);
        }
      }

      const urls: PreSignedUrl[] = response.data;

      console.log(urls);

      const successfulUploads: Array<{ file: File; urlObject: PreSignedUrl }> = [];

      const uploadPromises = urls.map((urlObject: { url: string; key: string; success: boolean }, index: number) => {
        const file = filesArray[index];
        const instance = axios.create({
          transformRequest: (data, headers) => {
            delete headers.authorization;
            return data;
          },
        });

        const res = instance
          .put(urlObject.url, filesArray[index], {
            headers: {
              "Content-Type": filesArray[index].type,
            },
            onUploadProgress: progressEvent => {
              const { loaded, total } = progressEvent;
              const percentCompleted = Math.round((loaded * 100) / total);
              uploadProgress[index] = percentCompleted;
              setUploadProgress([...uploadProgress]);
            },
          })
          .then((response: any) => {
            console.log("File uploaded successfully:", response);
            successfulUploads.push({ file, urlObject });
            return { success: true, data: response.data }; // Return success information if needed
          })
          .catch((error: any) => {
            console.error("Error uploading file:", error);
            return { success: false, error }; // Return error information
          });
        console.log("upload promise");
        console.log(res);
        return res;
      });

      await Promise.all(uploadPromises);

      if (successfulUploads.length > 0) {
        // Only proceed to create database records for successfully uploaded files
        const dbPromises = successfulUploads.map(({ file, urlObject }) =>
          TrainingDocumentFoldersService.createTrainingDocumentFiles({
            parentFolderId: id,
            fileNameInAwsBucket: urlObject.key,
            originalFileName: file.name,
            type: file.type,
            createdBy: userId,
            title: fileTitle,
            description: fileDescription,
          })
        );

        await Promise.all(dbPromises);
        setIsLoading(false);
        setIsFileUploading(false);

        setUploadStatus("Files uploaded and database records created successfully");
        toast.success("Files uploaded and database records created successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        toast.error("No files were uploaded successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setUploadStatus("No files were uploaded successfully");
      }
      setDropFiles([]);
      setUploadProgress([]);
    } catch (error) {
      setDropFiles([]);
      // setUploadStatus("Error uploading file");
      console.error("Failed Uploading Files:", error);
      toast.success("Files Upload and Db Records created successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      setUploadProgress([]);
    } finally {
      setDropFiles([]);
      setUploadProgress([]);
    }
  };

  const deleteFolders = useCallback(
    async (folderId: string) => {
      setIsDeletingFolder(false);
      if (
        (user && user?.grantedAccessFileFolderPermission) ||
        (user?.role && user?.role === Role.SUPER_ADMIN) ||
        (user?.role && user?.role === Role.SUB_ADMIN)
      ) {
        try {
          const result = await AdminService.deleteFoldersByDocumentPermissionByGrantedUser({ folderId });
          if (result.success) {
            toast.success("Folder successfully deleted", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setIsDeletingFolder(true);
          }
        } catch (error) {
          setIsDeletingFolder(false);
          toast.error("Unable to delete folder", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        setIsDeletingFolder(false);
        toast.error("You have not allowed to delete folders", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    },
    [setIsDeletingFolder]
  );

  const deleteFiles = useCallback(
    async (fileId: string) => {
      try {
          setFileTooltipOpen((prevState) => ({
            ...prevState,
           [fileId]: false,
         }));
        setIsFileDeleting(true);
        if (
          (user && user?.grantedAccessFileFolderPermission) ||
          (user?.role && user?.role === Role.SUPER_ADMIN) ||
          (user?.role && user?.role === Role.SUB_ADMIN)
        ) {
          setIsLoading(true);
          const result = await AdminService.deleteFilesByDocumentPermissionByGrantedUser({ fileId });

          if (result.success) {
            toast.success("File delete successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setIsFileDeleting(false);
            setIsLoading(false);
          }
        } else {
          setIsFileDeleting(false);
          toast.error("You have not allowed to delete file", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } catch (error) {
        setIsFileDeleting(false);
        toast.error("Unable to delete file", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } finally {
        setIsFileDeleting(false);
      }
    },
    [setIsFileDeleting]
  );

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideFile);
    return () => {
      document.removeEventListener("click", handleClickOutsideFile);
    };
  }, []);

  useEffect(() => {
    if (id) {
      getAllFilesAndFolders(id, sortOrder);
    }
  }, [id, createFolderModalOpen, fileFolderModalOpen, isFileUploading, isFileDeleting, isDeletingFolder, isOpenRenameFileModal, isOpenRenameFolderModal, sortOrder]);

  // Thêm hàm để toggle dropdown sắp xếp
  const toggleSortDropdown = () => setSortDropdownOpen(prevState => !prevState);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          {/* <SideNav tabNumber={tabNumber} toggleLeftMenuOpen={toggleLeftMenuOpen} isOpenLeftMenu={isOpenLeftMenu} sideNavTabNumber={sideNavTabNumber} /> */}
          <Col className="bg-transparent col-md-12 ">
            <div
              style={{
                backgroundColor: "#FDE9D8",
              }}
              className="d-flex rounded-top col-12 flex-col flex-col w-full py-2 px-3 flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-center gap-2"
            >
              <SearchComponent searchFilesAndFolder={searchFilesAndFolder} handleSearchString={handleSearchString} searchString={searchString} />
              
              {/* Dropdown để chọn thứ tự sắp xếp */}
              <div className="d-flex align-items-center mx-2">
                <Dropdown isOpen={sortDropdownOpen} toggle={toggleSortDropdown} className="me-2">
                  <DropdownToggle color="light" caret>
                    SORT: {sortOrder === "asc" ? "A → Z" : "Z → A"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => { 
                      // Lưu giá trị mới
                      const newOrder = "asc";
                      // Cập nhật state
                      setSortOrder(newOrder);
                      // Gọi API với giá trị mới
                      getAllFilesAndFolders(id, newOrder);
                    }}>A → Z</DropdownItem>
                    <DropdownItem onClick={() => { 
                      // Lưu giá trị mới
                      const newOrder = "desc";
                      // Cập nhật state
                      setSortOrder(newOrder);
                      // Gọi API với giá trị mới
                      getAllFilesAndFolders(id, newOrder);
                    }}>Z → A</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              
              <div className="d-flex gap-1" id="action-buttons">
                <DocumentButtonList
                  toggleCreateDropDown={toggleCreateDropDown}
                  toggleCreateFolderModal={toggleCreateFolderModal}
                  toggleFileFolderModal={toggleFileFolderModal}
                  toggle={toggle}
                  createDropdownOpen={createDropdownOpen}
                  uploadDropdownOpen={uploadDropdownOpen}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-start pt-2">
              <Button color="secondary" onClick={handleBack}>
                <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                Back
              </Button>
            </div>

            <Row className="mt-4">
              {isLoading ? (
                <div className="group-chat-empty-view">
                  <Spinner />
                </div>
              ) : (
                folders.length === 0 &&
                files.length === 0 &&
                isClickSearchButton && (
                  <div
                    className="d-flex flex-col justify-content-center align-items-center"
                    style={{ height: "65vh", backgroundColor: "white", border: "1px dashed #555" }}
                  >
                    <p>Search Result Not Found</p>
                  </div>
                )
              )}

              {isLoading ? (
                <div className="group-chat-empty-view">
                  <Spinner />
                </div>
              ) : (
                folders.length === 0 &&
                files.length === 0 &&
                !isClickSearchButton && (
                  <div
                    className="d-flex flex-col justify-content-center align-items-center"
                    style={{ height: "65vh", backgroundColor: "white", border: "2px dashed #555" }}
                  >
                    {dropFiles.length !== 0 && (
                      <Form className="col-md-6 col-10">
                        {fileDetailsError && <p className="text-center text-danger">{fileDetailsError}</p>}
                        <FormGroup>
                          <Label for="exampleEmail">Title</Label>
                          <Input
                            id="exampleEmail"
                            name="title"
                            placeholder="Title"
                            type="text"
                            value={fileTitle}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setFileTitle(e.target.value)}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="examplePassword">Description</Label>
                          <Input
                            id="examplePassword"
                            name="description"
                            placeholder="Description"
                            type="text"
                            value={fileDescription}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setFileDescription(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Form>
                    )}

                    <Card className="text-center col-sm-10 col-md-6" style={{ maxWidth: "500px" }}>
                      <CardBody {...getRootProps()}>
                        <div
                          className="col-md-6 mb-4"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1",
                          }}
                        >
                          <input {...getInputProps()} />
                          {
                            isDragActive ? <p>Drop the files here...</p> : null
                            // <p
                            //   style={{
                            //     textAlign: "center",
                            //     paddingTop: "15px",
                            //   }}
                            // >
                            //   Drag and drop some files here, or click to select files
                            // </p>
                          }
                        </div>

                        {dropFiles.length > 0 ? (
                          <>
                            {/* <div className="scrollable-list w-100 mb-2">
                            <ListGroup
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              className="w-40 text-center"
                            >
                              {dropFiles.map((file, index) => (
                                <div key={index}>
                                  <ListGroupItem
                                    key={index}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      textAlign: "center",
                                    }}
                                  >
                                    {file.name}
                                  </ListGroupItem>
                                  {uploadProgress[index] > 0 && <Progress value={uploadProgress[index]}>{uploadProgress}%</Progress>}
                                </div>
                              ))}
                            </ListGroup>
                          </div> */}
                            <div>
                              {dropFiles.map((file, index) => (
                                <div key={index}>
                                  <p>{file.name}</p>
                                  {uploadProgress[index] > 0 && <Progress value={uploadProgress[index]}>{uploadProgress}%</Progress>}
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <IoCloudUploadOutline size={30} />
                            </div>

                            <p>
                              Drop files here to upload,
                              <br /> or use the Upload button
                            </p>
                            {/* <button className="bg-gray">Upload</button> */}
                          </>
                        )}
                      </CardBody>
                      <div className="d-flex justify-content-center">
                        <Button disabled={isFileUploading} style={{ width: "100px" }} onClick={() => handleUpload()}>
                          {isFileUploading ? "Submitting" : "Submit"}
                        </Button>
                      </div>
                    </Card>
                  </div>
                )
              )}

              {!isLoading &&
                folders.map((elemrnt, key) => (
                  <Col key={key} lg="2" md="3" sm="4" xs="6">
                    <Card
                      style={{
                        border: "1px solid #ccd3de",
                        marginBottom: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CardBody>
                        {/* {elemrnt.type === "file" ? (
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                          <CiFileOn size={100} />
                          {elemrnt.name}
                        </div>
                      ) : ( */}
                        <FolderListComponent
                          folder={elemrnt}
                          selectedFolder={selectedFolder}
                          deleteFolders={deleteFolders}
                          isDeleteActionDropDownOpen={dropdownOpen[elemrnt._id] || false}
                          handleClickOutside={handleClickOutside}
                          handleContextMenu={handleContextMenu}
                          toggleDeleteActionDropDown={() => toggleDeleteActionDropDown(elemrnt)}
                          toggleFolderRenameModal={toggleFolderRenameModal}
                        />
                        {/* )} */}

                        {/* <Link to="training-documents/folder/87373838"></Link> */}
                      </CardBody>
                    </Card>
                  </Col>
                ))}

              {!isLoading &&
                files &&
                files?.map((file, key) => (
                  <Col key={key} md="3" sm="4" xs="6" lg="2">
                    <Card
                      style={{
                        border: "1px solid #ccd3de",
                        marginBottom: "1rem",
                        display: "flex",
                        flex: "wrap",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      id={`document-files-${file._id}`}
                      onMouseEnter={() => handleMouseEnter(file._id)}
                      onMouseLeave={() => handleMouseLeave(file._id)}
                    >
                      <CardBody
                        onClick={() => {
                          if (!rightClickedFile) handleOpenInNewTab(file);
                        }}
                      >
                        <div
                          onContextMenu={event => handleContextFileMenu(event, file._id)}
                          style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                        >
                          <CiFileOn size={110} />
                          <p
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "150px", // Adjust the max-width as needed
                            }}
                          >
                            {file.originalFileName}
                          </p>
                        </div>

                        {/* <Link to="training-documents/folder/87373838"></Link> */}
                      </CardBody>
                      <div>
                        {dropdownFileOpen[file._id] && (
                          <Dropdown
                            isOpen={dropdownFileOpen[file._id]}
                            toggle={() => setDropdownFileOpen({ [file._id]: !dropdownFileOpen[file._id] })}
                            style={
                              {
                                // position: "absolute",
                                // top: `${dropdownPosition.y}px`,
                                // left: `${dropdownPosition.x}px`,
                                // display: "block",
                              }
                            }
                          >
                            <DropdownToggle tag="div" />
                            <DropdownMenu>
                              <DropdownItem header>Actions</DropdownItem>
                              <DropdownItem onClick={() => deleteFiles(file._id)}>Delete File</DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  toggleRenameModalOpen();
                                  renameFileDetails(file);
                                }}
                              >
                                Rename File
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )}
                      </div>
                    </Card>

                      <Tooltip
                        toggle={() => toggleTooltip(file._id)}
                        isOpen={filetooltipOpen[file._id] || false}
                        target={`document-files-${file._id}`}
                        placement="bottom"
                      >
                        {file.title}
                      </Tooltip>

                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
        <FolderModal createFolder={createFolder} toggle={toggleCreateFolderModal} modal={createFolderModalOpen} />
        <FileFolderModal toggle={toggleFileFolderModal} modal={fileFolderModalOpen} handleFileChange={handleFileChange} items={items} parentFolderId={id} />
        <RenameFileModal
          isOpen={isOpenRenameFileModal}
          toggle={toggleRenameModalOpen}
          reanemFile={reanemFile}
          changeRenameFile={changeRenameFile}
          renameFileName={renameFileName}
        />

        <RenameFolderModal
          toggle={toggleFolderRenameModal}
          isOpen={isOpenRenameFolderModal}
          renameFolderName={renameFolderName}
          renameFolder={renameFolder}
          changeFolderName={changeFolderName}
        />

        {selectFile && (
          <FilePreviewModal
            isOpen={isFilePreviewModalOpen}
            toggle={toggleFilePreviewModal}
            fileType={selectFile.type}
            fileData={selectPresignUrl}
            description={selectFile.description}
            title={selectFile.title}
          />
        )}
      </Container>
    </div>
  );
};

export default FolderComponent;
